import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";

import { ShipmentType } from "@sellernote/_shared/src/types/common/common";
import {
  BidCopyStatus,
  BidCopyTarget,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getBidCopyTargetOptionList } from "@sellernote/_shared/src/utils/forwarding/admin/adminBid";

export default function useBidCopyTargetCheckbox({
  copyStatusOfRadio,
  isImport,
}: {
  copyStatusOfRadio: BidCopyStatus;
  isImport: boolean;
}) {
  const shipmentType: ShipmentType = isImport ? "importation" : "exportation";

  const defaultBidCopyTargetValue: BidCopyTarget = useMemo(() => {
    let checkBoxValues = {
      defaultInfo: true,
      copyEstimatePartner: false,
      contactEstimatePartner: false,
      copyEstimate: false,
      submitEstimate: false,
      acceptEstimate: false,
      copyExporterInfo: false,
      copyBookingPartner: false,
      contactBookingPartner: false,
    };

    if (copyStatusOfRadio === "waiting") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
      };
    }

    if (copyStatusOfRadio === "waitingForExporterInfo") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
        acceptEstimate: true,
      };
    }

    // 수출일때 수출자 컨택 전, 중 대신 사용하는 화물 준비 중 상태
    if (copyStatusOfRadio === "waitingFreight") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
        acceptEstimate: true,
      };
    }

    if (copyStatusOfRadio === "beforeContactPartner") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
        acceptEstimate: true,
        copyExporterInfo: true,
      };
    }

    if (copyStatusOfRadio === "contactingPartner") {
      checkBoxValues = {
        ...checkBoxValues,
        copyEstimate: true,
        submitEstimate: true,
        acceptEstimate: true,
        copyExporterInfo: true,
        copyBookingPartner: true,
        ...(shipmentType === "importation" && { contactBookingPartner: true }),
      };
    }

    return checkBoxValues;
  }, [copyStatusOfRadio, shipmentType]);

  const [bidCopyTargetValue, setBidCopyTargetValue] = useState(
    defaultBidCopyTargetValue
  );

  useEffect(() => {
    setBidCopyTargetValue(defaultBidCopyTargetValue);
  }, [defaultBidCopyTargetValue]);

  /**
   * 변경가능한 checkbox Option인지 확인
   * - 주의: checkbox의 선택상태가 아닌 disabled를 판단하기 위한 함수임
   */
  const checkIsActiveOption = useCallback(
    (option: keyof BidCopyTarget): boolean => {
      switch (option) {
        case "contactBookingPartner": {
          if (
            shipmentType === "importation" &&
            copyStatusOfRadio === "contactingPartner"
          ) {
            /** 수입운송의 경우, 부킹 파트너 컨택 없이 의뢰복사 가능 (#13817) */
            return true;
          }

          if (shipmentType === "exportation") {
            // 수출에서 '현지 파트너 컨택'기능 추가후 활성화 예정
            return false;
          }

          return false;
        }

        case "copyEstimatePartner": {
          if (copyStatusOfRadio !== "committed") {
            return true;
          }

          return false;
        }

        case "contactEstimatePartner": {
          if (shipmentType === "importation") {
            return copyStatusOfRadio === "estimating";
          }

          if (shipmentType === "exportation") {
            // 수출은 화물준비중 상태인 경우 "견적파트너 컨택" 활성화 필요
            return copyStatusOfRadio === "waitingFreight";
          }

          return false;
        }

        case "copyBookingPartner": {
          if (shipmentType === "importation") {
            return copyStatusOfRadio === "beforeContactPartner";
          }

          if (shipmentType === "exportation") {
            return copyStatusOfRadio === "waitingFreight";
          }

          return false;
        }

        case "copyExporterInfo": {
          if (shipmentType === "exportation") {
            return copyStatusOfRadio === "waitingFreight";
          }

          return false;
        }

        default: {
          return false;
        }
      }
    },
    [copyStatusOfRadio, shipmentType]
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return setBidCopyTargetValue({
        ...bidCopyTargetValue,
        [event.target.name]: event.target.checked,
      });
    },
    [bidCopyTargetValue]
  );

  const optionList = getBidCopyTargetOptionList(shipmentType);

  const BidCopyTargetCheckbox = useMemo(() => {
    return (
      <FormControl>
        <FormLabel>복사 대상</FormLabel>

        <FormGroup>
          {optionList.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              control={
                <Checkbox
                  checked={bidCopyTargetValue[value]}
                  name={value}
                  onChange={handleCheckboxChange}
                  disabled={!checkIsActiveOption(value)}
                  size="small"
                />
              }
              label={label}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  }, [
    bidCopyTargetValue,
    checkIsActiveOption,
    handleCheckboxChange,
    optionList,
  ]);

  return { BidCopyTargetCheckbox, bidCopyTargetValue };
}
