import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBid";
import BackTop from "@sellernote/_shared-for-forwarding-admin/src/components/BackTop";
import CommentList from "@sellernote/_shared-for-forwarding-admin/src/components/CommentList";
import Tabs from "@sellernote/_shared-for-forwarding-admin/src/components/Tabs";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useShipmentCommentList from "@sellernote/_shared-for-forwarding-admin/src/hooks/useShipmentCommentList";
import BasicDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/BasicDesc";
import CargoInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/CargoInfo";
import ConsolidationCheckPointDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/ConsolidationCheckPointDesc";
import ConsolidationEndAddress from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/ConsolidationEndAddress";
import ConsolidationTotalInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/ConsolidationTotalInfo";
import ExporterDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/ExporterDesc";
import QuotationsUser from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/QuotationsUser";
import ScheduleDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/ScheduleDesc";
import TransportationInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/TransportationInfo";
import WarehouseInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/WarehouseInfo";

import Loading from "components/Loading";

import DraftQuotation from "./_components/DraftQuotation";
import { BidPartnerProvider } from "./_hooks/useBidPartnerContext";
import BidCopyModal from "./BidCopyModal";
import BidPartner from "./BidPartner";
import PartnerQuote from "./PartnerQuote";

const boxLayoutStyle: SxProps<Theme> = {
  p: 1,
  background: "#fff",
};

const BidDetail = () => {
  const [tabDefaultActiveKey, setTabDefaultActiveKey] = useRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_DETAIL_TAB_DEFAULT_ACTIVE_KEY
  );

  const [showsBidCopyModal, setShowsBidCopyModal] = useState(false);

  const { id }: { id: string } = useParams();

  const { data: bidDetail } = ADMIN_BID_QUERY.useGetAdminBidDetail({
    bidId: Number(id),
  });

  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: zoneList } = ADMIN_COMMON_QUERY.useGetZoneList();

  const { data: countryList } = ADMIN_COMMON_QUERY.useGetCountryList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: bidDetail?.locale,
  });

  const {
    handleCommentInputChange,
    handleCommentUpdate,
    comment,
    shipmentCommentList,
    handleCommentEdit,
    handleCommentDelete,
  } = useShipmentCommentList({ shipmentId: Number(id) });

  // 견적지원 시 변경한 tab key를 원래대로 돌려놓는다(기본 0).
  useEffect(() => {
    return () => {
      setTabDefaultActiveKey(0);
    };
  }, [setTabDefaultActiveKey]);

  if (
    !bidDetail ||
    !adminUserList ||
    !portList ||
    !zoneList ||
    !countryList ||
    !exchangeRateList
  ) {
    return (
      <Layout breadcrumbs={["입찰관리"]} title={"입찰상세 정보"}>
        <Loading></Loading>
      </Layout>
    );
  }

  return (
    <Layout title="의뢰 상세정보">
      <BackTop />

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <Button
            variant="contained"
            onClick={() => setShowsBidCopyModal(true)}
          >
            의뢰복사
          </Button>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography variant="h6">기본 정보</Typography>

          <BasicDesc bidDetail={bidDetail} adminUserList={adminUserList} />
        </Box>

        <Divider sx={{ my: 1 }} />

        {bidDetail.serviceType !== "consolidation" && (
          <Box sx={boxLayoutStyle}>
            <TransportationInfo
              bidDetail={bidDetail}
              portData={portList}
              zoneData={zoneList}
              countryList={countryList}
            />
          </Box>
        )}

        <Divider sx={{ my: 1 }} />

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {bidDetail.serviceType === "consolidation" && (
            <>
              <Box sx={boxLayoutStyle}>
                <Typography variant="h6">수출자 및 화물 정보</Typography>

                <CargoInfo bidDetail={bidDetail} />
              </Box>

              <Divider sx={{ my: 1 }} />
            </>
          )}

          {bidDetail.serviceType !== "consolidation" && (
            <>
              <Box sx={boxLayoutStyle}>
                <ExporterDesc bidDetail={bidDetail}></ExporterDesc>
              </Box>

              <Divider sx={{ my: 1 }} />
            </>
          )}

          <Box sx={boxLayoutStyle}>
            <ScheduleDesc bidDetail={bidDetail} />
          </Box>

          <Divider sx={{ my: 1 }} />

          {bidDetail.quotationsUser.length > 0 && bidDetail.accountPayables && (
            <>
              <Box sx={boxLayoutStyle}>
                <BidPartnerProvider bidDetail={bidDetail}>
                  <BidPartner bidDetail={bidDetail} />
                </BidPartnerProvider>
              </Box>

              <Divider sx={{ my: 1 }} />
            </>
          )}

          <Box sx={boxLayoutStyle}>
            <PartnerQuote bidDetail={bidDetail} />
          </Box>

          <Divider sx={{ my: 1 }} />

          <Box sx={{ ...boxLayoutStyle, display: "flex" }}>
            <CommentList
              commentDataList={bidDetail.management.comments}
              commentPageType={"AutoShipment"}
            />

            <CommentList
              comment={comment}
              onCommentInputChange={handleCommentInputChange}
              onCommentUpdate={handleCommentUpdate}
              commentDataList={shipmentCommentList}
              commentPageType="ManualShipment"
              onCommentEdit={handleCommentEdit}
              onCommentDelete={handleCommentDelete}
            />
          </Box>

          <Divider sx={{ my: 1 }} />

          {bidDetail.serviceType === "consolidation" && (
            <>
              <ConsolidationTotalInfo bidDetail={bidDetail} />
              <Divider sx={{ my: 1 }} />
            </>
          )}

          {bidDetail.serviceType === "consolidation" && (
            <>
              <ConsolidationCheckPointDesc bidDetail={bidDetail} />
              <Divider sx={{ my: 1 }} />
            </>
          )}

          {bidDetail.serviceType === "consolidation" && (
            <>
              <ConsolidationEndAddress bidDetail={bidDetail} />
              <Divider sx={{ my: 1 }} />
            </>
          )}

          {bidDetail.serviceType === "consolidation" && (
            <>
              <WarehouseInfo bidDetail={bidDetail} />
              <Divider sx={{ my: 1 }} />
            </>
          )}

          <Box sx={boxLayoutStyle}>
            {bidDetail.serviceType === "consolidation" ? (
              <Tabs
                defaultTabPanelValue={tabDefaultActiveKey}
                tabsData={[
                  {
                    tabLabel: "견적서 초안",
                    tabPanelItem: (
                      <DraftQuotation shipmentDetailData={bidDetail} />
                    ),
                  },

                  {
                    tabLabel: "확정 견적",
                    tabPanelItem: bidDetail.quotationsUser.length > 0 && (
                      <QuotationsUser shipmentDetail={bidDetail} />
                    ),
                  },
                ]}
              />
            ) : (
              <Tabs
                defaultTabPanelValue={tabDefaultActiveKey}
                tabsData={[
                  {
                    tabLabel: "화물정보 및 상세",
                    tabPanelItem: <CargoInfo bidDetail={bidDetail} />,
                  },
                  {
                    tabLabel: "견적서 초안",
                    tabPanelItem: (
                      <DraftQuotation shipmentDetailData={bidDetail} />
                    ),
                  },

                  {
                    tabLabel: "확정 견적",
                    tabPanelItem: bidDetail.quotationsUser.length > 0 && (
                      <QuotationsUser shipmentDetail={bidDetail} />
                    ),
                  },
                ]}
              />
            )}
          </Box>
        </Box>
      </Box>

      {showsBidCopyModal && (
        <BidCopyModal
          showsBidCopyModal={showsBidCopyModal}
          setShowsBidCopyModal={setShowsBidCopyModal}
          bidDetail={bidDetail}
        />
      )}
    </Layout>
  );
};

export default withRequireAuth(BidDetail);
