import { useCallback, useMemo } from "react";
import { UseFormWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import {
  CHECK_IS_OCEANTICKET_RES,
  CREATE_BID_REQ_AIR,
  CREATE_BID_REQ_DESTINATION,
  CREATE_BID_REQ_FCL,
  CREATE_BID_REQ_LCL,
  CREATE_BID_REQ_ORIGIN,
  GET_FCL_ESTIMATE_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import ADMIN_BID_CREATE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_CREATE_QUERY";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import {
  FreightType,
  Liner,
  Port,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  BidCreateFormData,
  BidCreateQuotationStorageData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getLinerId,
  getQuotationDataCurrency,
} from "@sellernote/_shared/src/utils/forwarding/admin/adminBid";
import {
  getCountryOfPort,
  getPortId,
  handleShipmentCreateSuccess,
} from "@sellernote/_shared/src/utils/forwarding/admin/adminBidCreate";
import {
  findExchangeRate,
  getAllTotalItemPriceOfFeeData,
} from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import { getContainersInfoPayload } from "pages/bid/create/BidCreateForm/utils";
import useGetProductsInfoWithUnitSupply from "pages/bid/create/useGetProductsInfoWithUnitSupply";

// TODO: useCreateBidButton과 공통 부분을 묶어서 하나로 수정
export default function useConfirmQuotationButton({
  portList,
  bidCreateFormData,
  isOceanticket,
  freightType,
  watch,
  linerList,
  exchangeRateList,
  /** 실시간 견적 데이터 */
  estimate,
  hasPO,
  POId,
  formIndex,
  sessionStorageQuotationInfo,
  sessionStorageShipmentCreateUserInfo,
  handleSessionStorageShipmentCreateInfoChange,
  sessionStorageShipmentCreateInfo,
}: {
  portList: Port[] | undefined;
  bidCreateFormData: BidCreateFormData;
  isOceanticket: CHECK_IS_OCEANTICKET_RES | undefined;
  freightType: FreightType;
  watch: UseFormWatch<ApplyBidFormData>;
  linerList: Liner[] | undefined;
  exchangeRateList: ExchangeRate[] | undefined;
  estimate: GET_FCL_ESTIMATE_RES | undefined;
  hasPO?: boolean;
  POId?: number;
  formIndex: number;
  sessionStorageQuotationInfo: BidCreateQuotationStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { mutate: createBid, isLoading } =
    ADMIN_BID_CREATE_QUERY.useCreateBid();

  // TODO: payload를 만드는 코드가, 의뢰생성 페이지에서 사용하는 코드와 중복임. 합쳐서 통합관리할 필요가 있음
  const getQuotationInfo = useCallback(() => {
    return {
      currency: getQuotationDataCurrency({
        localFee: watch("localFeeData"),
        freightFee: watch("freightFeeData"),
        domesticFee: watch("domesticFeeData"),
        inlandFee: watch("inlandFeeData"),
        otherFee: watch("otherFeeData"),
        taxFee: watch("taxFeeData"),
        exchangeRateList: exchangeRateList,
      }),
      userId: sessionStorageShipmentCreateUserInfo.userId,
      freightFee: watch("freightFeeData"),
      domesticFee: watch("domesticFeeData"),
      localFee: watch("localFeeData"),
      taxFee: watch("taxFeeData"),
      inlandFee: watch("inlandFeeData"),
      otherFee: watch("otherFeeData"),
      freightPaymentType: watch("freightPaymentType"),
      comment: watch("comment") || "",
      startCountry:
        bidCreateFormData.startType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.startPort)
          : getCountryOfPort(portList, bidCreateFormData.startViaPort),
      startPortId: bidCreateFormData.startPort
        ? getPortId(portList, bidCreateFormData.startPort)
        : getPortId(portList, bidCreateFormData.startViaPort),
      endCountry:
        bidCreateFormData.endType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.endPort)
          : getCountryOfPort(portList, bidCreateFormData.endViaPort),
      endPortId: bidCreateFormData.endPort
        ? getPortId(portList, bidCreateFormData.endPort)
        : getPortId(portList, bidCreateFormData.endViaPort),
      endZoneId: bidCreateFormData.zoneId,
      incoterms: bidCreateFormData.incoterms,
      freightType: freightType,
      volumn: "",
      liner: watch("liner"),
      linerId: !getLinerId(linerList, watch("liner"))
        ? undefined
        : getLinerId(linerList, watch("liner")),
      leadtime: watch("leadtime"),
      isTransit: watch("isTransit"),
      exchangeRate: findExchangeRate(exchangeRateList, "USD"),
      totalPrice: getAllTotalItemPriceOfFeeData(
        watch("freightFeeData"),
        watch("domesticFeeData"),
        watch("localFeeData"),
        watch("taxFeeData"),
        watch("inlandFeeData"),
        watch("otherFeeData")
      ),
      expiredAt: watch("expiredAt"),
    };
  }, [
    watch,
    exchangeRateList,
    sessionStorageShipmentCreateUserInfo.userId,
    bidCreateFormData.startType,
    bidCreateFormData.startPort,
    bidCreateFormData.startViaPort,
    bidCreateFormData.endType,
    bidCreateFormData.endPort,
    bidCreateFormData.endViaPort,
    bidCreateFormData.zoneId,
    bidCreateFormData.incoterms,
    portList,
    freightType,
    linerList,
  ]);

  const getRequestOriginData = useCallback(() => {
    const origin: CREATE_BID_REQ_ORIGIN = {
      startCountry:
        bidCreateFormData.startType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.startPort)
          : getCountryOfPort(portList, bidCreateFormData.startViaPort),
      startPortId:
        bidCreateFormData.startType !== "inland"
          ? getPortId(portList, bidCreateFormData.startPort)
          : undefined,
      startViaPortId:
        bidCreateFormData.startType === "inland"
          ? getPortId(portList, bidCreateFormData.startViaPort)
          : undefined,
      startAddress: bidCreateFormData.startAddress,
      startType: bidCreateFormData.startType,
      startAddressDetail: bidCreateFormData.startAddressDetail,
    };

    return origin;
  }, [
    bidCreateFormData.startAddress,
    bidCreateFormData.startAddressDetail,
    bidCreateFormData.startPort,
    bidCreateFormData.startType,
    bidCreateFormData.startViaPort,
    portList,
  ]);

  const getRequestDestinationData = useCallback(() => {
    const origin: CREATE_BID_REQ_DESTINATION = {
      endCountry:
        bidCreateFormData.endType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.endPort)
          : getCountryOfPort(portList, bidCreateFormData.endViaPort),
      endPortId:
        bidCreateFormData.endType !== "inland"
          ? getPortId(portList, bidCreateFormData.endPort)
          : undefined,
      endViaPortId:
        bidCreateFormData.endType === "inland"
          ? getPortId(portList, bidCreateFormData.endViaPort)
          : undefined,
      endAddress: bidCreateFormData.endAddress,
      endType: bidCreateFormData.endType,
      endAddressDetail: bidCreateFormData.endAddressDetail,
      zoneId:
        bidCreateFormData.endType === "inland"
          ? bidCreateFormData.zoneId
          : undefined,
    };

    return origin;
  }, [
    bidCreateFormData.endAddress,
    bidCreateFormData.endAddressDetail,
    bidCreateFormData.endPort,
    bidCreateFormData.endType,
    bidCreateFormData.endViaPort,
    bidCreateFormData.zoneId,
    portList,
  ]);

  /**
   * 발주물품 정보를 물품정보 폼에서 추출해주는 함수
   */
  const getPOProducts = useCallback(() => {
    if (freightType === "FCL" && sessionStorageQuotationInfo.isOpenApiAuth) {
      const productsArray = bidCreateFormData.containersInfo?.map(
        ({ products, ...containerItem }) => {
          return products;
        }
      );

      return productsArray?.flat() as
        | {
            productName: string;
            separator?: string;
          }[]
        | undefined;
    }

    if (sessionStorageQuotationInfo.isOpenApiAuth) {
      return bidCreateFormData.products;
    }
  }, [
    bidCreateFormData.containersInfo,
    bidCreateFormData.products,
    freightType,
    sessionStorageQuotationInfo.isOpenApiAuth,
  ]);

  const getRequestFclData = useCallback(() => {
    const fcl: CREATE_BID_REQ_FCL = {
      containerAccessable:
        bidCreateFormData.endType === "inland"
          ? bidCreateFormData.containerAccessable === "TRUE"
          : undefined,
      containOceanSurcharge: bidCreateFormData.containOceanSurcharge
        ? bidCreateFormData.containOceanSurcharge === "TRUE"
        : undefined,
      containLss: bidCreateFormData.containLss
        ? bidCreateFormData.containLss === "TRUE"
        : undefined,
      containersInfo: getContainersInfoPayload({
        containersInfo: bidCreateFormData.containersInfo,
        isOpenApiAuth: sessionStorageShipmentCreateInfo.isOpenApiAuth,
      }),
    };

    return fcl;
  }, [
    bidCreateFormData.containLss,
    bidCreateFormData.containOceanSurcharge,
    bidCreateFormData.containerAccessable,
    bidCreateFormData.containersInfo,
    bidCreateFormData.endType,
    sessionStorageShipmentCreateInfo.isOpenApiAuth,
  ]);

  const { getProductsInfoWithUnitSupply } = useGetProductsInfoWithUnitSupply({
    freightType,
    cargoInfoFormType,
    createFormData: bidCreateFormData,
  });

  const getRequestLclData = useCallback(() => {
    const lcl: CREATE_BID_REQ_LCL = {
      supply: bidCreateFormData.supply,
      containOceanSurcharge: bidCreateFormData.containOceanSurcharge
        ? bidCreateFormData.containOceanSurcharge === "TRUE"
        : undefined,
      containLss: bidCreateFormData.containLss
        ? bidCreateFormData.containLss === "TRUE"
        : undefined,
      totalCBM:
        cargoInfoFormType === "totalVolume"
          ? undefined
          : bidCreateFormData.totalCBM,
      totalWeight:
        cargoInfoFormType === "totalVolume"
          ? undefined
          : bidCreateFormData.totalWeight,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return lcl;
  }, [
    cargoInfoFormType,
    bidCreateFormData.containLss,
    bidCreateFormData.containOceanSurcharge,
    bidCreateFormData.supply,
    bidCreateFormData.totalCBM,
    bidCreateFormData.totalWeight,
    getProductsInfoWithUnitSupply,
  ]);

  const getRequestAirData = useCallback(() => {
    const air: CREATE_BID_REQ_AIR = {
      supply: bidCreateFormData.supply,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return air;
  }, [bidCreateFormData.supply, getProductsInfoWithUnitSupply]);

  const handleQuotationConfirm = useCallback(() => {
    createBid(
      {
        pathParams: {
          userId: sessionStorageShipmentCreateUserInfo.userId,
          teamId: sessionStorageShipmentCreateUserInfo.teamId,
        },
        origin: getRequestOriginData(),
        destination: getRequestDestinationData(),
        fcl:
          bidCreateFormData.freightType === "FCL"
            ? getRequestFclData()
            : undefined,
        lcl:
          bidCreateFormData.freightType === "LCL"
            ? getRequestLclData()
            : undefined,
        air:
          bidCreateFormData.freightType === "AIR"
            ? getRequestAirData()
            : undefined,
        freightType: bidCreateFormData.freightType,
        serviceType: isOceanticket ? "oceanTicket" : "general",
        transportType: bidCreateFormData.freightType === "AIR" ? "air" : "sea",
        /** 현재는 1로 고정 */
        exportersCount: 1,

        incoterms: bidCreateFormData.incoterms,
        ...(bidCreateFormData.endType === "inland" && {
          inlandType: (() => {
            if (bidCreateFormData.freightType === "FCL") {
              // FCL이면 독차 고정
              return "sole";
            }

            return bidCreateFormData.inlandType;
          })(),
        }),
        containCustoms: bidCreateFormData.containCustoms,
        containDomesticFee: bidCreateFormData.containDomesticFee
          ? bidCreateFormData.containDomesticFee === "TRUE"
          : false,
        hopeCargoInsurance: bidCreateFormData.hopeCargoInsurance,
        freeStorageDays:
          isOceanticket && bidCreateFormData.containsWarehouseFee === "TRUE"
            ? 7
            : undefined,
        needFTACertificateAgency: bidCreateFormData.needFTACertificateAgency,
        needIORAgency: bidCreateFormData.needIORAgency,
        isFixed: true,
        fareId: estimate?.fare?.id,
        estimateLogId: estimate?.estimateLogId,
        price: getAllTotalItemPriceOfFeeData(
          watch("freightFeeData"),
          watch("domesticFeeData"),
          watch("localFeeData"),
          watch("taxFeeData"),
          watch("inlandFeeData"),
          watch("otherFeeData")
        ),
        quotationInfoForDownload: estimate?.quotationInfoForDownload,
        quotationInfo: getQuotationInfo(),
        hasPo: hasPO,
        poId: POId,
        products: getPOProducts(),
        invoicePrice: sessionStorageQuotationInfo.isOpenApiAuth
          ? bidCreateFormData.invoicePrice
          : undefined,
      },
      {
        onSuccess: (response) => {
          const handleMoveToOrderPage = () => {
            history.push(`/order/${sessionStorageQuotationInfo.POId}`);
          };

          const handleMoveToShipmentCreatePage = () => {
            history.replace("/bid/create");
          };

          const handleMoveToShipmentDetailPage = () => {
            history.replace(`/bid/detail/${response.data.id}`);
          };

          handleShipmentCreateSuccess({
            hasPO,
            sessionStorageShipmentCreateInfo,
            formIndex,
            formData: bidCreateFormData,
            handleMoveToOrderPage,
            handleMoveToShipmentCreatePage,
            handleMoveToShipmentDetailPage,
            handleSessionStorageShipmentCreateInfoChange,
          });
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    createBid,
    sessionStorageShipmentCreateUserInfo.userId,
    sessionStorageShipmentCreateUserInfo.teamId,
    getRequestOriginData,
    getRequestDestinationData,
    bidCreateFormData,
    getRequestFclData,
    getRequestLclData,
    getRequestAirData,
    isOceanticket,
    estimate?.fare?.id,
    estimate?.estimateLogId,
    estimate?.quotationInfoForDownload,
    watch,
    getQuotationInfo,
    hasPO,
    POId,
    getPOProducts,
    sessionStorageQuotationInfo.isOpenApiAuth,
    sessionStorageQuotationInfo.POId,
    sessionStorageShipmentCreateInfo,
    formIndex,
    handleSessionStorageShipmentCreateInfoChange,
    history,
    handleSnackbarOpen,
  ]);

  const ConfirmQuotationButton = useMemo(() => {
    return (
      <Grid item>
        <Button type="submit" variant="contained" disabled={isLoading}>
          {isLoading ? <CircularProgress size={25} /> : "견적확정"}
        </Button>
      </Grid>
    );
  }, [isLoading]);

  return { ConfirmQuotationButton, handleQuotationConfirm };
}
